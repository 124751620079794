import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, image: metaImage, title, pathname }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						siteURL
					}
				}
			}
		`
	)
	const metaDescription = description || site.siteMetadata.description
	const image = metaImage ? `${site.siteMetadata.siteURL}${metaImage}`: `${site.siteMetadata.siteURL}/social-image.png`
	const canonical = pathname ? `${site.siteMetadata.siteURL}${pathname}` : null

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			link={
				canonical
					? [
							{
								rel: "canonical",
								href: canonical,
							},
						]
					: []
			}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			]
				.concat(
					metaImage
						? [
								{
									property: "og:image",
									content: image,
								},
								// {
								// 	property: "og:image:width",
								// 	content: metaImage.width,
								// },
								// {
								// 	property: "og:image:height",
								// 	content: metaImage.height,
								// },
								{
									name: "twitter:card",
									content: "summary_large_image",
								},
							]
						: [
								{
									name: "twitter:card",
									content: "summary",
								},
							]
				)
				.concat(meta)}
		/>
	)
}
SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}
SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	pathname: PropTypes.string,
}

export default SEO